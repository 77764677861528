import store from "@/store";
import router from "@/router";
import moment from "moment";
import { SurveyScheduleStatusEnum } from "@/store/enums/SurveyScheduleStatusEnum";
import { SurveyQuestionsLengthEnum } from "@/store/enums/SurveyQuestionsLengthEnum";
import { SelectCustomersContainer } from "@/store/models/bank/feedback/SelectCustomersContainer";
import { CustomQuestionsContainer } from "@/store/models/shared/CustomQuestionsContainer";
import { getDateDiff, getEndOfDay } from "@/buying-teams/helpers/DateHelper";

type feedbackSessionPayloadType = {
    surveyId: number,
    selectedCustomers: any,
    selectedCustomQuestions: any,
    scheduleStatus: SurveyScheduleStatusEnum,
    title: string,
    description: string,
    length: SurveyQuestionsLengthEnum,
    phases: string[],
    productAreas: string[],
    countries: string[],
    sessionStart: Date,
    sessionEnd: Date,
}

export enum BankFeedbackStepsEnum {
    SET_BASIC_INFO = 1,
    SESSION_DESCRIPTION = 2,
    SET_QUESTIONNAIRE = 3,
    SELECT_CUSTOMER = 4,
    SESSION_SUMMARY = 5,
}

const STEPS_COUNT = 5;

export class BankFeedbackCreationContainer {
    allProductAreas: string[] = [];
    allCountries: string[] = [];
    stepsCount: number = STEPS_COUNT;
    currentStep: BankFeedbackStepsEnum = BankFeedbackStepsEnum.SET_BASIC_INFO;
    customersData: SelectCustomersContainer | null = null;
    customQuestionsData: CustomQuestionsContainer | null = null;
    questionsDataList: any[] = [];
    isCustomQuestionsOpen: boolean = false;
    isSelectDeadlineTouched: boolean = false;

    feedbackSessionPayload: feedbackSessionPayloadType = {
        surveyId: 0,
        selectedCustomers: [],
        selectedCustomQuestions: [],
        scheduleStatus: SurveyScheduleStatusEnum.NOW,
        title: "",
        description: "",
        length: SurveyQuestionsLengthEnum.STANDARD,
        phases: [...store.getters.phases],
        productAreas: [],
        countries: [],
        sessionStart: new Date(),
        sessionEnd: new Date()
    };

    constructor() {
    }

    setProductAreasData(productAreas) {
        this.allProductAreas = productAreas;
        this.feedbackSessionPayload.productAreas = this.allProductAreas;
    }

    setCountriesData(countries) {
        this.allCountries = countries;
        this.feedbackSessionPayload.countries = this.allCountries;
    }

    get stepsPercentage() {
        return this.currentStep <= (this.stepsCount - 1) ? this.currentStep * (100 / this.stepsCount) : 100;
    }

    get questionsLength() {
        return this.feedbackSessionPayload.length === SurveyQuestionsLengthEnum.QUICK
            ? 3 : this.feedbackSessionPayload.length === SurveyQuestionsLengthEnum.STANDARD
                ? 7 : 14;
    }

    get differenceDay() {
        return getDateDiff(this.feedbackSessionPayload.sessionEnd, this.feedbackSessionPayload.sessionStart);
    }

    get feedbackSessionDraftPayload() {
        return {
            title: this.feedbackSessionPayload.title,
            description: this.feedbackSessionPayload.description,
            phases: this.feedbackSessionPayload.phases,
            product_areas: this.feedbackSessionPayload.productAreas,
            length: this.feedbackSessionPayload.length,
            session_start: this.feedbackSessionPayload.sessionStart,
            session_end: getEndOfDay(this.feedbackSessionPayload.sessionEnd),
            countries: this.feedbackSessionPayload.countries,
            repeat_session: ""
        };
    }

    get feedbackSessionLaunchPayload() {
        return {
            survey_id: this.feedbackSessionPayload.surveyId,
            title: this.feedbackSessionPayload.title,
            description: this.feedbackSessionPayload.description,
            banks: [
                {
                    id: store.getters.bank.id,
                    countries: this.feedbackSessionPayload.countries
                }
            ]
        };
    }

    setCustomersData(response: any) {
        this.customersData = new SelectCustomersContainer(response);
    }

    setCustomQuestionsData(questions: any[]) {
        if (this.customQuestionsData) {
            this.customQuestionsData.setSavedQuestionsData(questions);
        } else {
            this.customQuestionsData = new CustomQuestionsContainer(questions, this.feedbackSessionPayload.productAreas);
        }
        this.isCustomQuestionsOpen = true;
    }

    checkResetCustomQuestionsContainer() {
        if (this.customQuestionsData && (JSON.stringify(this.customQuestionsData?.surveyProductAreas) !== JSON.stringify(this.feedbackSessionPayload.productAreas))) {
            this.customQuestionsData.resetQuestionContainer(this.feedbackSessionPayload.productAreas);
            this.feedbackSessionPayload.selectedCustomQuestions = [];
        }
    }

    setDefaultEndDate() {
        let date = new Date(moment(this.feedbackSessionPayload.sessionStart).format());
        let endDate: any = moment(date.setDate(date.getDate() + this.questionsLength));

        if (!this.isSelectDeadlineTouched || (this.isSelectDeadlineTouched && this.differenceDay <= 2)) {
            this.feedbackSessionPayload.sessionEnd = new Date(endDate);
            this.isSelectDeadlineTouched = false;
        }

        this.feedbackSessionPayload.sessionStart = new Date(moment(this.feedbackSessionPayload.sessionStart).format());
    }

    setQuestionsData(surveyId: number, questions: any[]) {
        this.feedbackSessionPayload.surveyId = surveyId;

        let productAreas: Set<string> = new Set<string>();
        let phases: Set<string> = new Set<string>();

        questions.forEach((item: any) => {
            productAreas.add(item.product_area);
            phases.add(item.phase);
        });

        this.questionsDataList = Array.from(productAreas.values()).map(area => {
            return {
                title: area,
                phases: Array.from(phases.values()).map(phase => {
                    return {
                        title: phase,
                        questionList: questions.filter(item => item.phase === phase && item.product_area === area).map(item => {
                            return {
                                question: item.question,
                                is_enabled: true,
                                id: item.id
                            };
                        })
                    };
                })
            };
        });
    }

    activeQuestionsCount(value = []) {
        let count = 0;
        value.forEach((item: any) => {
            count += item.questionList.filter(val => val.is_enabled).length;
        });
        return count;
    }

    handleSelectCustomers() {
        this.feedbackSessionPayload.selectedCustomers = this.customersData?.getFinalData ? this.customersData?.getFinalData : [];
    }

    setSelectedCustomQuestions() {
        this.feedbackSessionPayload.selectedCustomQuestions = this.customQuestionsData?.newQuestions
            .filter((q) => q.isValid())
            .map((q) => q.payloadData);
    }

    nextStep() {
        this.currentStep++;

        switch (this.currentStep) {
            case BankFeedbackStepsEnum.SESSION_DESCRIPTION: {
                if (!this.feedbackSessionPayload.title) {
                    this.feedbackSessionPayload.title = this.feedbackSessionPayload.phases.join(", ");
                }
                if (!this.feedbackSessionPayload.description) {
                    this.feedbackSessionPayload.description = this.feedbackSessionPayload.productAreas.join(", ");
                }
                this.setDefaultEndDate();
                return;
            }
        }
    }

    prevStep() {
        if (this.currentStep !== BankFeedbackStepsEnum.SET_BASIC_INFO) {
            this.currentStep--;
        } else {
            router.go(-1);
        }
    }

}
