<template>
    <CustomLoader v-if="loading" />
    <template v-else>
        <div class="bank-new-feedback" v-if="!creationContainer.isCustomQuestionsOpen">
            <div class="bank-new-feedback__header">
                <button class="header-back-btn" @click="creationContainer.prevStep()">
                    <inline-svg src="/media/buying/icons/arrow-right.svg" />
                    <span>{{ creationContainer.currentStep === BankFeedbackStepsEnum.SET_BASIC_INFO ? $t('Cancel') : $t('Back') }}</span>
                </button>
                <div class="bank-new-feedback__header--step">
                <span class="text-gray">
                    {{ $t("Step") + " " + creationContainer.currentStep + " " + $t("of") + " " + creationContainer.stepsCount }}
                </span>
                    <div class="demo-progress step-progress w-100">
                        <el-progress class="w-100" :stroke-width="4" :percentage="creationContainer.stepsPercentage" />
                    </div>
                    <div class="step-progress"></div>
                </div>
            </div>

            <!-- Step 1 -->
            <BankStepOneBasicInfo
                v-if="creationContainer.currentStep === BankFeedbackStepsEnum.SET_BASIC_INFO"
                :creationContainer="creationContainer"
            />

            <!-- Step 2 -->
            <BankStepTwoDescriptionDeadline
                v-if="creationContainer.currentStep === BankFeedbackStepsEnum.SESSION_DESCRIPTION"
                :creationContainer="creationContainer"
            />

            <!-- Step 3 -->
            <BankStepThreeQuestionnaire
                v-if="creationContainer.currentStep === BankFeedbackStepsEnum.SET_QUESTIONNAIRE"
                :creationContainer="creationContainer"
            />

            <!-- Step 4 -->
            <BankStepFiveSelectCustomers
                v-if="creationContainer.currentStep === BankFeedbackStepsEnum.SELECT_CUSTOMER"
                :creationContainer="creationContainer"
            />

            <!-- Step 5 -->
            <BankStepFiveSessionSummary
                v-if="creationContainer.currentStep === BankFeedbackStepsEnum.SESSION_SUMMARY"
                :creationContainer="creationContainer"
            />
        </div>
        <div class="bank-new-feedback" v-else>
            <CustomQuestionsContainer
                :questionsContainer="creationContainer.customQuestionsData"
                @handleBack="handleBack"
                @continueWithSelectedQuestions="onContinueWithSelectedQuestions"
            />
        </div>
    </template>
</template>

<script>
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
    BankFeedbackCreationContainer,
    BankFeedbackStepsEnum
} from "@/store/models/bank/feedback/BankFeedbackCreationContainer";
import BankStepOneBasicInfo from "@/buying-teams/pages/bank/feedbacks/feedbacks-creation/BankStepOneBasicInfo";
import BankStepTwoDescriptionDeadline from "@/buying-teams/pages/bank/feedbacks/feedbacks-creation/BankStepTwoDescriptionDeadline";
import BankStepThreeQuestionnaire from "@/buying-teams/pages/bank/feedbacks/feedbacks-creation/BankStepThreeQuestionnaire";
import BankStepFiveSelectCustomers from "@/buying-teams/pages/bank/feedbacks/feedbacks-creation/BankStepFiveSelectCustomers";
import BankStepFiveSessionSummary from "@/buying-teams/pages/bank/feedbacks/feedbacks-creation/BankStepFiveSessionSummary";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import CustomQuestionsContainer from "@/buying-teams/shared-components/custom-questions/CustomQuestionsContainer";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";

export default {
    name: "BankFeedbackCreate",
    components: {
        CustomQuestionsContainer,
        CustomLoader,
        BankStepFiveSessionSummary,
        BankStepFiveSelectCustomers,
        BankStepThreeQuestionnaire, BankStepTwoDescriptionDeadline, BankStepOneBasicInfo},
    data() {
       return {
           loading: true,
           BankFeedbackStepsEnum,
           creationContainer: new BankFeedbackCreationContainer()
       }
    },
    computed: {
        authUser() {
            return store.getters.currentUser;
        }
    },
    mounted() {
        if (this.authUser && !this.authUser.can(BankUserPermissionTypeEnum.CREATE_FEEDBACK_SESSION)) {
            this.$router.push('/bank/feedback/feedback-sessions');
        }

        setCurrentPageBreadcrumbs({
            title: this.$t('New Feedback session'),
            subTitle: this.$t('Get valuable insights from your customers')
        });
        this.getStaticData();
    },
    methods: {
        getStaticData() {
            return store.dispatch('fetchBankUserData', this.authUser.id)
                .then((res) => {
                    // TODO check !
                    // this.creationContainer.setProductAreasData(Object.keys(res.bank.product_areas));
                    // this.creationContainer.setCountriesData(Object.keys(res.bank.countries));
                    this.creationContainer.setProductAreasData(res.bank_product_areas);
                    this.creationContainer.setCountriesData(res.bank_countries);
                }).finally(() => {
                    this.loading = false;
                })
        },
        handleBack() {
            Swal.fire({
                title: this.$t('Cancel and back to step NUM', { n: 3 }),
                text: this.$t('Are you sure you want to go back to step NUM, the selected questions will be discarded', { n: 3 }),
                showCancelButton: true,
                confirmButtonText: this.$t('Discard changes'),
                customClass: 'cancel-and-back',
                cancelButtonText: this.$t('Cancel')
            }).then((result) => {
                if (result.value) {
                    this.creationContainer.isCustomQuestionsOpen = false;
                }
            })
        },
        onContinueWithSelectedQuestions() {
            this.creationContainer.setSelectedCustomQuestions();
            this.creationContainer.isCustomQuestionsOpen = false;
        }
    }
}
</script>

<style lang="scss">
.bank-new-feedback {
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 20px 53px 48px 38px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--title {
            font-weight: 500;
            font-size: 18px;
            line-height: 170.02%;
            color: #4F4F4F;
        }
        &--step {
            .el-progress__text {
                display: none;
            }
            .text-gray {
                font-weight: 400;
                font-size: 18px;
                line-height: 178.52%;
                color: #434343;
                opacity: 0.6;
            }
        }
    }
    .header-back-btn {
        font-weight: 500;
        border: none;
        font-size: 18px;
        line-height: 170.02%;
        color: #4F4F4F;
        background: transparent;
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
            transform: rotate(-180deg);
        }
        path {
            stroke: #4F4F4F;
        }
    }
    .continue-button {
        width: 334px;
        height: 48px;
        @media (max-width: 400px) {
            width: 100%;
        }
    }

    @media (max-width: 600px) {
        padding: 20px 24px;
    }
}
</style>
