<template>
    <el-dialog
        v-model="visibility"
        center
        width="1200px"
        :show-close="true"
        @close="$emit('close')"
        custom-class="customer-include-modal"
    >
        <template #title>
            <div class="customer-include-modal__header">
                <h1>{{ $t('Customers included') }}</h1>
                <span>
                    <img src="/media/buying/icons/office-building.svg" width="14" alt="">
                    {{ creationContainer.feedbackSessionPayload.selectedCustomers.length }}
                </span>
            </div>
        </template>

        <div class="select-customer mt-0">
            <div class="select-customer__content" v-if="creationContainer.feedbackSessionPayload.selectedCustomers.length">
                <div
                    class="select-customer__content--item"
                    v-for="(item, index) in creationContainer.feedbackSessionPayload.selectedCustomers"
                    :key="index"
                >
                    <div class="content--check">
                        <div class="content--user">
                            <img :src="item.icon_path" alt="">
                            <span>{{ item.business_name }}</span>
                        </div>
                    </div>
                    <div class="content--right">
                        <div class="content--counts">
                            <div class="content--counts-value">
                                <span>{{ item.getProductAreas.length }}</span>/<span>{{
                                    creationContainer.customersData.productAreas.length
                                }}</span>
                            </div>
                            <div class="content--counts-title">{{ $t('Product Areas Match') }}</div>
                        </div>
                        <div class="content--counts">
                            <div class="content--counts-value">
                                <span>{{ item.getCountries.length }}</span>/<span>
                                    {{ creationContainer.customersData.countries.length }}
                                </span>
                            </div>
                            <div class="content--counts-title">{{ $t('Countries Match') }}</div>
                        </div>
                        <div class="content--counts-right">
                            <div class="content--counts-user">
                                <img src="/media/buying/icons/users-icon.svg" alt="">
                                <span>{{ item.matchedSelectedUsers.length }}/{{ item.users.length }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { BankFeedbackCreationContainer } from "@/store/models/bank/feedback/BankFeedbackCreationContainer";

export default {
    name: "CustomersIncludedModal",
    props: {
        visibility: Boolean,
        creationContainer: [Object, BankFeedbackCreationContainer]
    }
}
</script>

<style lang="scss">
.customer-include-modal {
    &__header {
        display: flex;
        align-items: center;

        h1 {
            text-align: left;
            font-weight: 700;
            font-size: 18px;
            line-height: 178.52%;
            color: #000000;
            opacity: 0.8;
            margin-bottom: 0;
        }

        span {
            background: #F8F8F8;
            border-radius: 6.58407px;
            padding: 8px 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
            font-weight: 600;
            font-size: 16px;
            line-height: 158.02%;
            color: #2A2A2A;

            img {
                margin-right: 8px;
            }
        }
    }

    .select-customer {
        max-height: 500px;
        overflow-y: auto;

        &__content {
            &--item {
                height: 68px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 16px;
                padding-right: 14px;
                background: #fff;
                position: relative;

                &--wrapper {
                    width: 100%;
                    height: 68px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-left: 19px;
                    cursor: pointer;
                }

                &:after {
                    content: "";
                    position: absolute;
                    height: 1px;
                    background: rgba(0, 0, 0, 0.1);
                    left: 16px;
                    right: 14px;
                    bottom: 0;
                }

                &.is-active {
                    background: #EEF6FF;
                }

                .el-checkbox__inner {
                    width: 20px;
                    height: 20px;

                    &::after {
                        left: 7px;
                        top: 3px;
                    }
                }

                .el-checkbox__input.is-checked .el-checkbox__inner::after {
                    transform: rotate(45deg) scaleY(1.4);
                }

                .el-checkbox__input.is-disabled + span.el-checkbox__label {
                    cursor: pointer;
                }

                .el-checkbox__input.is-disabled .el-checkbox__inner {
                    background-color: #ffffff;
                    cursor: pointer;
                }

                .content--check {
                    .el-checkbox {
                        height: auto;

                        .el-checkbox__label {
                            padding-left: 19px;
                        }
                    }

                    .content--user {
                        display: flex;
                        align-items: center;

                        img {
                            width: 36px;
                            height: 36px;
                            margin-right: 16px;
                            border-radius: 50%;
                        }

                        span {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 158.02%;
                            color: #2B2B2B;

                            span {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 158.02%;
                                color: #FF5959;
                                margin-left: 16px;
                            }
                        }
                    }
                }

                .content--right {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }

                .content--counts {
                    display: flex;
                    align-items: center;

                    &:first-child {
                        margin-right: 43px;
                    }

                    &-value {
                        background: #FFFFFF;
                        border: 2px solid #435BF4;
                        border-radius: 6px;
                        height: 34px;
                        min-width: 34px;
                        margin-right: 9px;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 158.02%;
                        color: #435BF4;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            &:last-child {
                                font-size: 8px;
                            }
                        }

                        &.is-error {
                            border: 2px solid rgba(67, 91, 244, 0.16);
                            color: #FF5959;
                        }
                    }

                    &-title {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 158.02%;
                        color: #000000;
                    }

                    &-right {
                        margin-left: 23px;
                        min-width: 100px;
                        display: flex;
                        justify-content: flex-end;
                    }

                    &-user {
                        background: #F8F8F8;
                        border-radius: 6.58407px;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 158.02%;
                        color: #2B2B2B;
                        padding: 8px;
                        display: flex;
                        align-items: center;

                        img {
                            margin-right: 8px;
                        }
                    }
                }
            }

            &--footer {
                margin-top: 24px;
                display: flex;
                justify-content: space-between;
                padding-left: 16px;
                padding-right: 14px;
                align-items: center;

                p {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 19px;
                    color: #626262;
                    margin-bottom: 0;
                    width: 40%;
                }

                &--btn {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    color: #435BF4;
                    background: #FFFFFF;
                    border: 1px solid rgba(67, 91, 244, 0.1);
                    border-radius: 30px;
                    padding: 14.5px;
                    min-width: 330px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        filter: invert(30%) sepia(38%) saturate(3899%) hue-rotate(223deg) brightness(98%) contrast(95%);
                        transform: rotate(180deg);
                        width: 11px;
                        margin-right: 8px;
                    }
                }

                @media (max-width: 1024px) {
                    flex-direction: column;
                    p {
                        width: 100%;
                    }
                    button {
                        margin-top: 20px;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .customer-include-modal {
        --el-dialog-width: 98% !important;
    }
}

@media (max-width: 768px) {
    .customer-include-modal {
        .select-customer__content--item {
            flex-direction: column;
            align-items: flex-start;
            height: auto;
            padding: 10px 0;

            &:not(:last-child):after {
                left: 0;
            }

            .content--check {
                margin-bottom: 15px;
            }

            .content--right {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                .content--counts-user {
                    margin-left: 0;
                }
            }
        }
    }
}
</style>
