<template>
    <div class="bank-new-feedback__card" id="bank-new-feedback-s2">
        <el-form
            :model="creationContainer.feedbackSessionPayload"
            ref="createFeedbackFormRef"
            :rules="createFeedbackFormRules"
        >
            <div class="form-item mb-14">
                <div class="sa-step3__label">{{ $t('Title') }}</div>
                <div class="title-input">
                    <el-form-item prop="title">
                        <el-input v-model="creationContainer.feedbackSessionPayload.title" :placeholder="$t('Enter Session Title')" />
                    </el-form-item>
                </div>
            </div>
            <div class="form-item">
                <div class="sa-step3__label">{{ $t('Description') }}</div>
                <div class="description-input">
                    <el-form-item prop="description">
                        <el-input
                            v-model="creationContainer.feedbackSessionPayload.description"
                            type="textarea"
                            :rows="5"
                            :placeholder="$t('Enter Session Description')"
                        />
                    </el-form-item>
                </div>
                <div class="description-info">
                    <span>{{ $t('The Title and Description of a session is never shared with the Banks.') }}</span>
                    <span>{{ creationContainer.feedbackSessionPayload.description.length }}/{{ maxDescriptionLength }}</span>
                </div>
            </div>
        </el-form>
        <div class="sa-step3__footer row">
            <div class="col-xl-8">
                <div class="row align-items-center mb-3">
                    <div class="col-md-6">
                        <label class="select-deadline-label">{{ $t("Select Deadline") }}</label>
                    </div>
                    <div class="col-md-6">
                        <el-date-picker
                            class="select-deadline-input"
                            v-model="creationContainer.feedbackSessionPayload.sessionEnd"
                            :disabled-date="disabledDate"
                            type="date"
                            :clearable="false"
                            placeholder="Select date and time"
                            format="dddd, D MMM'YY"
                            @change="creationContainer.isSelectDeadlineTouched = true"
                        />
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <label class="select-deadline-text">{{ $t("Feedback session is active for:") }}</label>
                    </div>
                    <div class="col-md-6">
                        <div class="select-deadline-days">{{ creationContainer.differenceDay }} {{ $t('Days') }}</div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 d-flex justify-content-end align-items-end">
                <button
                    :data-kt-indicator="loading ? 'on' : 'off'"
                    :disabled="loading"
                    class="main-btn d-block btn continue-button"
                    @click.prevent="handleNextStep()"
                >
                    <span class="indicator-label"> {{ $t('Continue') }}</span>

                    <span class="indicator-progress">
                          {{ $t("pleaseWait") }}
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import store from "@/store";
import { BankFeedbackCreationContainer } from "@/store/models/bank/feedback/BankFeedbackCreationContainer";

export default {
    name: "BankStepTwoDescriptionDeadline",
    props: {
        creationContainer: [Object, BankFeedbackCreationContainer]
    },
    data() {
        return {
            loading: false,
            maxDescriptionLength: 300,
        }
    },
    computed: {
        createFeedbackFormRules() {
            return {
                title: [
                    { required: true, message: this.$t("Please input title"), trigger: "change" }
                ],
                description: [
                    { required: true, message: this.$t("Please input description"), trigger: "change" }
                ]
            }
        }
    },
    methods: {
        disabledDate(time) {
            let date = new Date(moment(this.creationContainer.feedbackSessionPayload.sessionStart).format());
            return time.getTime() < Date.parse(moment(date.setDate(date.getDate() + 2)).format('MM-DD-YYYY'));
        },
        handleNextStep() {
            this.loading = true;
            this.$refs.createFeedbackFormRef.validate((valid) => {
                if (valid) {
                    store.dispatch('createBankSurveysDraft', this.creationContainer.feedbackSessionDraftPayload).then(res => {
                        this.creationContainer.setQuestionsData(res.data.survey_id, res.data.questions)
                        this.creationContainer.nextStep();
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss">
#bank-new-feedback-s2 {
    margin-top: 41px;
    .sa-step3 {
        &__label {
            font-weight: 400;
            font-size: 24px;
            line-height: 170.02%;
            color: #A8A5A5;
        }
        &__footer {
            margin-top: 58px;
        }
    }
    .title-input {
        .el-input__inner {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 13px;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            font-weight: 500;
            font-size: 36px;
            line-height: 170.02%;
            color: #4F4F4F;
            height: 59px;
        }
    }
    .description-input {
        .el-textarea__inner {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 13px;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            font-weight: 500;
            font-size: 18px;
            line-height: 170.02%;
            color: #4F4F4F;
        }
    }
    .description-info {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 12px;
        line-height: 170.02%;
        color: #CCCCCC;
        margin-top: 7px;
    }
    .select-deadline-label {
        font-weight: 500;
        font-size: 18px;
        line-height: 170.02%;
        color: #4F4F4F;
    }
    .select-deadline-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }
    .select-deadline-days {
        background: #F6F7FE;
        border-radius: 3px;
        font-weight: 500;
        font-size: 16px;
        line-height: 170.02%;
        color: #435BF4;
        padding: 4px 10px;
        width: max-content;
    }
    .select-deadline-input {
        height: 34px;
        width: auto;
        .el-input__inner {
            height: 34px;
            background: rgba(230, 230, 230, 0.26);
            border-radius: 3px;
            border: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 170.02%;
            color: #000000;
            padding-right: 5px;
        }
        .el-icon svg path {
            fill: #000;
        }
    }

    @media (max-width: 992px) {
        .sa-step3__label {
            font-size: 18px;
        }
        .title-input .el-input__inner {
            font-size: 20px;
        }
        .description-input .el-textarea__inner {
            font-size: 16px;
        }
        .sa-step3__footer {
            .col-xl-4 {
                margin-top: 20px;
            }
            .col-xl-8 >div:first-child {
                margin-bottom: 25px !important;
            }
        }
    }
}
</style>
