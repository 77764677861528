<template>
    <div class="selected-customers-item">
        <div class="selected-customers-item__header">
            <div class="selected-customers-item__icon">
                <img :src="item.icon_path" alt="">
            </div>
            <div class="selected-customers-item__content">
                <div class="selected-customers-item__top">
                    <div class="selected-customers-item__title">
                        <span>{{ item.business_name }}</span>
                        <div class="customer-status" :class="item.isRegistered ? 'registered-customer' : 'not-registered'">
                            <inline-svg src="/media/buying/icons/customer-status.svg"></inline-svg>
                            <span>{{ item.isRegistered ? $t('Registered Customer') : $t('Not Registered') }}</span>
                        </div>
                    </div>
                    <div class="selected-customers-item__buttons">
                        <button @click="$emit('onClickItem')">
                            <inline-svg src="/media/buying/icons/edit_black.svg"></inline-svg>
                        </button>
                        <button @click="$emit('onRemove')">
                            <inline-svg src="/media/buying/icons/delete-icon.svg"></inline-svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="selected-customers-item__footer">
            <div class="selected-customers-item__bottom">
                <div class="content--counts" :class="{'is-error': !item.getCountries.length}">
                    <div class="content--counts-value">
                        <span>{{ item.getCountries.length }}</span>/<span>{{ customersData.countries.length }}</span>
                    </div>
                    <div class="content--counts-title">{{ $t('Countries') }}</div>
                </div>
                <div class="content--counts" :class="{'is-error': !item.getProductAreas.length}">
                    <div class="content--counts-value">
                        <span>{{ item.getProductAreas.length }}</span>/<span>{{ customersData.productAreas.length }}</span>
                    </div>
                    <div class="content--counts-title">{{ $t('Product Areas') }}</div>
                </div>
            </div>
            <div class="selected-customers-item__users">
                <div class="content-counts-user">
                    <inline-svg src="/media/buying/icons/users-icon.svg"></inline-svg>
                    <span>{{ item.matchedSelectedUsers.length }}/{{ item.users.length }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SelectedCustomersItem",
    props: {
        item: Object,
        customersData: Object,
    },
    emits: ['onClickItem', 'onRemove']
}
</script>

<style lang="scss">
.selected-customers-item {
    padding: 14px 20px 16px 20px;
    display: flex;
    flex-direction: column;
    &__icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid transparent;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__content {
        width: calc(100% - 36px);
        padding-left: 12px;
    }
    &__top {
        display: flex;
        justify-content: space-between;
    }
    &__header {
        display: flex;
    }
    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
    }
    &__title {
        > span {
            font-weight: 600;
            font-size: 16px;
            line-height: 158.02%;
            color: #2B2B2B;
        }
        .customer-status {
            span {
                font-weight: 500;
                font-size: 10px;
                line-height: 158.02%;
                margin-left: 4px;
            }
            &.registered-customer {
                path {
                    fill: #2BC490;
                }
                span {
                    color: #2BC490;
                }
            }
            &.not-registered {
                path {
                    fill: #C4C4C4;
                }
                span {
                    color: #C4C4C4;
                }
            }
        }
    }
    &__buttons {
        display: flex;
        gap: 24px;
        button {
            width: 24px;
            height: 24px;
            padding: 0;
            display: flex;
            border: none;
            background: transparent;
            border-radius: 5px;
            transition: .3s ease-in-out;
            path {
                fill: #5D5D5D;
            }
            &:hover {
                background: #e4e7e9;
                path {
                    fill: #0A0A0A;
                }
            }
        }
    }
    &__bottom {
        display: flex;
        gap: 16px;
        .content--counts {
            display: flex;
            align-items: center;
            background: #FFFFFF;
            border: 2px solid #435BF4;
            border-radius: 6px;
            padding: 2px 9px;
            .content--counts-value {
                font-weight: 800;
                font-size: 15px;
                line-height: 158.02%;
                color: #435BF4;
                margin-right: 5px;
                span:last-child {
                    font-size: 11px;
                }
            }
            .content--counts-title {
                font-weight: 300;
                font-size: 12px;
                line-height: 158.02%;
                color: #000000;
            }
            &.is-error {
                border: 2px solid rgba(67, 91, 244, 0.16);
                .content--counts-value {
                    color: #FF5959;
                }
            }
        }
    }
    &__users {
        display: flex;
        align-items: center;
        .content-counts-user {
            background: #2BC490;
            border-radius: 6.58407px;
            font-weight: 700;
            font-size: 16px;
            line-height: 158.02%;
            color: #FFFFFF;
            padding: 4px 8px;
            display: flex;
            align-items: center;
            svg {
                margin-right: 8px;
            }
            path {
                fill: #fff;
            }
        }
        .content-arrow {
            background: transparent;
            border: none;
            margin-left: 10px;
        }
    }
}
</style>
