<template>
    <div class="all-customers-item" :class="{'item-is-selected': item.isSelected}" @click="$emit('onClickItem')">
        <div class="all-customers-item__icon">
            <div class="item-icon">
                <div class="item-icon--front">
                    <img :src="item.icon_path" alt="">
                </div>
                <div class="item-icon--back">
                    <inline-svg src="/media/buying/icons/check-icon.svg" />
                </div>
            </div>
        </div>
        <div class="all-customers-item__content">
            <div class="all-customers-item__top">
                <div class="all-customers-item__title">
                    <span>{{ item.business_name }}</span>
                    <div class="customer-status" :class="item.isRegistered ? 'registered-customer' : 'not-registered'">
                        <inline-svg src="/media/buying/icons/customer-status.svg"></inline-svg>
                        <span>{{ item.isRegistered ? $t('Registered Customer') : $t('Not Registered') }}</span>
                    </div>
                </div>
                <div class="all-customers-item__users">
                    <div class="content-counts-user">
                        <inline-svg src="/media/buying/icons/users-icon.svg"></inline-svg>
                        <span>{{ item.matchedSelectedUsers.length }}/{{ item.users.length }}</span>
                    </div>
                    <button class="content-arrow">
                        <inline-svg src="/media/buying/icons/arrow-right-dark.svg"></inline-svg>
                    </button>
                </div>
            </div>
            <div class="all-customers-item__bottom">
                <div class="content--counts" :class="{'is-error': !item.getCountries.length}">
                    <div class="content--counts-value">
                        <span>{{ item.getCountries.length }}</span>/<span>{{ customersData.countries.length }}</span>
                    </div>
                    <div class="content--counts-title">{{ $t('Countries') }}</div>
                </div>
                <div class="content--counts" :class="{'is-error': !item.getProductAreas.length}">
                    <div class="content--counts-value">
                        <span>{{ item.getProductAreas.length }}</span>/<span>{{ customersData.productAreas.length }}</span>
                    </div>
                    <div class="content--counts-title">{{ $t('Product Areas') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "AllCustomersItem",
    props: {
        item: Object,
        customersData: Object,
    },
    emits: ['onClickItem']
}
</script>

<style lang="scss">
.all-customers-item {
    padding: 14px 20px 16px 20px;
    display: flex;
    cursor: pointer;
    &__icon {
        border: 1px solid transparent;
        perspective: 600px;
        .item-icon {
            width: 36px;
            height: 36px;
            transition: transform .5s;
            transform-style: preserve-3d;
            position: relative;
        }
        .item-icon--front {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .item-icon--back {
            transform: rotateY(180deg);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            background: #FFFFFF;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.05);

        }
        .item-icon--front, .item-icon--back {
            position: absolute;
            width: 36px;
            height: 36px;
            line-height: 260px;
            z-index: 1;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
        img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    &__content {
        width: calc(100% - 36px);
        padding-left: 12px;
    }
    &__top {
        display: flex;
        justify-content: space-between;
    }
    &__bottom {
        display: flex;
        gap: 16px;
        margin-top: 14px;
        .content--counts {
            display: flex;
            align-items: center;
            background: #FFFFFF;
            border: 2px solid #435BF4;
            border-radius: 6px;
            padding: 2px 9px;
            .content--counts-value {
                font-weight: 800;
                font-size: 15px;
                line-height: 158.02%;
                color: #435BF4;
                margin-right: 5px;
                span:last-child {
                    font-size: 11px;
                }
            }
            .content--counts-title {
                font-weight: 300;
                font-size: 12px;
                line-height: 158.02%;
                color: #000000;
            }
            &.is-error {
                border: 2px solid rgba(67, 91, 244, 0.16);
                .content--counts-value {
                    color: #FF5959;
                }
            }
        }
    }
    &__users {
        display: flex;
        align-items: center;
        .content-counts-user {
            background: #F8F8F8;
            border-radius: 6.58407px;
            font-weight: 600;
            font-size: 12px;
            line-height: 158.02%;
            color: #2B2B2B;
            padding: 8px;
            display: flex;
            align-items: center;
            svg {
                margin-right: 8px;
            }
        }
        .content-arrow {
            background: transparent;
            border: none;
            margin-left: 10px;
        }
    }
    &__title {
        > span {
            font-weight: 600;
            font-size: 16px;
            line-height: 158.02%;
            color: #2B2B2B;
        }
        .customer-status {
            span {
                font-weight: 500;
                font-size: 10px;
                line-height: 158.02%;
                margin-left: 4px;
            }
            &.registered-customer {
                path {
                    fill: #2BC490;
                }
                span {
                    color: #2BC490;
                }
            }
            &.not-registered {
                path {
                    fill: #C4C4C4;
                }
                span {
                    color: #C4C4C4;
                }
            }
        }
    }

    &:not(.item-is-selected):hover {
        background: #fbfbfb;
    }

    &.item-is-selected {
        background: rgba(99, 170, 252, 0.08);
        border-radius: 5.28234px;
        .all-customers-item__icon {
            .item-icon {
                transform: rotateY(180deg);
            }
            path {
                fill: #435BF4;
            }
        }
        .content-counts-user {
            background: #2BC490;
            color: #fff;
            path {
                fill: #fff;
            }
        }
    }
}
</style>
